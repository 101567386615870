.btn {
  border-radius: $btnRadius;
  font-size: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  // min-height: $btnHeightMobile;
  min-height: $btnHeightMobile;
  color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(sm) {
    min-height: $btnDesktopHeight;
    padding: 2px 20px;
  }

  &:hover {
    text-decoration: none !important;
    color: #fff !important;
  }

  &-link {
    &:hover {
      color: #0086bb !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &--inherit-height {
    min-height: inherit;
  }

  &--small {
    height: 32px;
    line-height: 1;
  }

  &--small-mobile {
    @include media-breakpoint-down(sm) {
      height: 32px;
      min-height: 32px;
      // min-width: 100px;
      line-height: 1;
    }
  }

  &--standard {
    background-color: #26a0e3;
    color: #fff;

    &:hover {
      background-color: darken($color: #26a0e3, $amount: 10);
      color: #f1f1f1;
    }
  }

  &--gray {
    background-color: $gray-800;
    color: #fff;

    &:hover {
      background-color: darken($color: $gray-800, $amount: 10);
      color: #f1f1f1;
    }
  }

  &--red {
    color: #fff;
    background-color: #b10c1c;
    border-color: #b10c1c;
    margin-top: 15px;
    font-size: 10px;

    @include media-breakpoint-up(lg) {
      margin-top: 25px;
      font-size: 16px;
    }
  }

  &--outline {
    border: 1px solid #999;
    background-color: #fff;
    color: #000;

    &:hover {
      background-color: darken($color: #999, $amount: 1);
    }
  }

  &--outline-blue {
    border: 1px solid #00aaee;
    background-color: #fff;
    color: #00aaee;

    &:hover {
      background-color: darken($color: #00aaee, $amount: 1);
    }

    &-transparent {
      @extend .btn--outline-blue;
      background-color: transparent;
    }
  }

  &--disabled,
  &.disabled,
  &:disabled {
    background-color: $gray-800;
    color: #fff;
    cursor: not-allowed;
    opacity: 0.5;
    border-color: transparent;
  }

  &--white {
    color: $gray-900;
    background-color: #fff;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
      color: $gray-800 !important;
    }
  }

  &--dark {
    background-color: $gray-900;
    color: color-yiq($gray-900);
  }
}
