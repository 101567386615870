// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 992px,
    lg: 1290px,
    xl: 1400px,
);

// Grid globals
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 750px,
    md: 970px,
    lg: 1170px,
    xl: 1310px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 20 !default;
$grid-gutter-width: 10px !default;
$grid-gutter-width-lg: 15px !default;

// Border
//
// Set the default properties of the border

$border-color: $gray-600;

// Spacer
//
// Set the default properties of the spacer
$spacer: 5px;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: $spacer,
        2: (
            $spacer * 2,
        ),
        3: (
            $spacer * 3,
        ),
        4: (
            $spacer * 4,
        ),
        5: (
            $spacer * 5,
        ),
        6: (
            $spacer * 6,
        ),
        7: (
            $spacer * 7,
        ),
        8: (
            $spacer * 8,
        ),
    ),
    $spacers
);

// modal sizes
$modal-xl: 1285px;
$modal-lg: 985px;
$modal-md: 500px;
$modal-sm: 370px;

// input
$input-placeholder-color: $gray-800;
$input-border-color: #d7d7d7;

// text
$font-weight-bold: 500;

// modal
$modal-md: 600px;

$enable-rounded: false;
$yiq-contrasted-threshold: 160;

$text-muted: $gray-800;

$theme-colors: (
    "questions": #ffa918,
    "solutions": #06ed94,
    "views": #8bdeff,
    "shares": #33c5ff,
);
