$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("./icons.eot?4cc0c59878f1564c1d6f8d770e74ea82#iefix") format("embedded-opentype"),
url("./icons.woff2?4cc0c59878f1564c1d6f8d770e74ea82") format("woff2"),
url("./icons.woff?4cc0c59878f1564c1d6f8d770e74ea82") format("woff");
}

span[class^="icon-"]:before, span[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "add": "\f101",
    "back-arrow": "\f102",
    "burger-menu": "\f103",
    "collapse-arrow": "\f104",
    "email": "\f105",
    "engagements": "\f106",
    "expand-arrow": "\f107",
    "facebook": "\f108",
    "first-page": "\f109",
    "instagram": "\f10a",
    "last-page": "\f10b",
    "linkedin": "\f10c",
    "next": "\f10d",
    "nextV2": "\f10e",
    "note": "\f10f",
    "prev": "\f110",
    "previous": "\f111",
    "question-circle": "\f112",
    "search": "\f113",
    "sort-asc": "\f114",
    "sort-desc": "\f115",
    "sort-unset": "\f116",
    "twitter-x": "\f117",
    "youtube": "\f118",
);

.icon-add:before {
    content: map-get($icons-map, "add");
}
.icon-back-arrow:before {
    content: map-get($icons-map, "back-arrow");
}
.icon-burger-menu:before {
    content: map-get($icons-map, "burger-menu");
}
.icon-collapse-arrow:before {
    content: map-get($icons-map, "collapse-arrow");
}
.icon-email:before {
    content: map-get($icons-map, "email");
}
.icon-engagements:before {
    content: map-get($icons-map, "engagements");
}
.icon-expand-arrow:before {
    content: map-get($icons-map, "expand-arrow");
}
.icon-facebook:before {
    content: map-get($icons-map, "facebook");
}
.icon-first-page:before {
    content: map-get($icons-map, "first-page");
}
.icon-instagram:before {
    content: map-get($icons-map, "instagram");
}
.icon-last-page:before {
    content: map-get($icons-map, "last-page");
}
.icon-linkedin:before {
    content: map-get($icons-map, "linkedin");
}
.icon-next:before {
    content: map-get($icons-map, "next");
}
.icon-nextV2:before {
    content: map-get($icons-map, "nextV2");
}
.icon-note:before {
    content: map-get($icons-map, "note");
}
.icon-prev:before {
    content: map-get($icons-map, "prev");
}
.icon-previous:before {
    content: map-get($icons-map, "previous");
}
.icon-question-circle:before {
    content: map-get($icons-map, "question-circle");
}
.icon-search:before {
    content: map-get($icons-map, "search");
}
.icon-sort-asc:before {
    content: map-get($icons-map, "sort-asc");
}
.icon-sort-desc:before {
    content: map-get($icons-map, "sort-desc");
}
.icon-sort-unset:before {
    content: map-get($icons-map, "sort-unset");
}
.icon-twitter-x:before {
    content: map-get($icons-map, "twitter-x");
}
.icon-youtube:before {
    content: map-get($icons-map, "youtube");
}
