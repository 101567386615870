/* You can add global styles to this file, and also import other style files */
// variables
@import "./styles/global/variables";

/* override*/
@import "./styles/global/override";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Global Styles */
@import "./styles/global/functions";
@import "./styles/global/mixins";
@import "./styles/global/container";
@import "./styles/global/utilities";
@import "./styles/global/animations";
@import "./styles/global/typography";
@import "./styles/global/font";
@import "./styles/global/layout";

// Sprite
@import "./styles/components/sprites";
@import "./styles/components/spritesOverride";

// Font icons 
@import "./styles/font-icons/icons.scss";
@import "./styles/components/icons";

/* Import Component SCSS files here. */
@import "./styles/components/toast";
@import "./styles/components/loader";
@import "./styles/components/header";
@import "./styles/components/footer";
@import "./styles/components/button";
@import "./styles/components/card";

/* Import Page Component SCSS files here */
@import "./styles/pages/home";
