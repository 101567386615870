.footer-minimal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  height: 27px;

  @include media-breakpoint-up(sm) {
    height: 48px;
  }

  span {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 7px;

    @include media-breakpoint-up(sm) {
      font-size: 12px;
    }
  }
}

// for home video

// .footer-minimal {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #121212;
//   height: 48px;

//   @include media-breakpoint-down(xs) {
//     height: 38px;
//   }

//   span {
//     margin: 0;
//     padding: 0;
//     color: #fff;
//     font-size: 12px;

//     @include media-breakpoint-down(xs) {
//       font-size: 11px;
//     }
//   }
// }
