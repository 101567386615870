@font-face {
    font-family: "myriad-pro";
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: local("myriad-pro"),
        url("~src/assets/public/fonts/myriad/myriad-pro-webfont.woff2") format("woff2"),
        url("~src/assets/public/fonts/myriad/myriad-pro-webfont.woff") format("woff");
}

@font-face {
    font-family: "myriad-pro";
    font-weight: 300;
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: local("myriad-pro"),
        url("~src/assets/public/fonts/myriad/myriad-pro-light.woff2")
            format("woff2"),
        url("~src/assets/public/fonts/myriad/myriad-pro-light.woff")
            format("woff");
}

@font-face {
    font-family: "myriad-pro";
    font-weight: 500;
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: local("myriad-pro"),
        url("~src/assets/public/fonts/myriad/myriad-pro-semibold.woff2")
            format("woff2"),
        url("~src/assets/public/fonts/myriad/myriad-pro-semibold.woff")
            format("woff");
}
