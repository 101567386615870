body {
  //   padding-top: 54px;
  //   padding-bottom: 54px; // for mobile header adjustment
  background-color: $body-bg-color;

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }
}

.container {
  padding-left: $space-2;
  padding-right: $space-2;
}

.gutters {
  @include gutters(default);
  &-sm {
    @include gutters(sm);
  }
  &-md {
    @include gutters(md);
  }
  &-lg {
    @include gutters(lg);
  }
}

// scrollbar
::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f1f1f1;
  direction: rtl;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(175, 172, 172, 0.3);
  box-shadow: inset 0 0 6px rgba(175, 172, 172, 0.3);
  background-color: #cfcfcf;
}
