.home {
  height: calc(100vh - 27px); // vertical height minus footer size

  background: url("/assets/public/images/common/backgrounds/hero-mobile.jpg")
    lightgray 50% / cover no-repeat;

  @include media-breakpoint-up(sm) {
    height: calc(100vh - 48px);
    background: url("/assets/public/images/common/backgrounds/hero-banner-center.jpg")
      lightgray 50% / cover no-repeat;
  }

  @include media-breakpoint-down(sm) {
    @media only screen and (orientation: landscape) {
      background: url("/assets/public/images/common/backgrounds/hero-banner-center.jpg")
        lightgray 50% / cover no-repeat;
      height: 100%;
    }
  }

  &__section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding-bottom: 24%;

    @include media-breakpoint-up(sm) {
      padding: 110px 0 80px;
    }

    @include media-breakpoint-down(sm) {
      @media only screen and (orientation: landscape) {
        padding: 20px 0 40px;
      }
    }

    &-logo {
      height: 50px;
      margin-top: 12%;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
        height: 76px;
      }

      @include media-breakpoint-down(sm) {
        @media only screen and (orientation: landscape) {
          margin-top: 40px;
        }
      }
    }

    &-content {
      padding: 0 20px;

      @include media-breakpoint-up(sm) {
        padding: 0;
      }

      @include media-breakpoint-down(sm) {
        @media only screen and (orientation: landscape) {
          margin-top: 50%;
        }
      }

      p {
        font-size: 1rem;
        color: #000;
        margin-bottom: 0;
        line-height: normal;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) {
          font-size: 24px;

          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

.home2 {
  $this: &;
  // prevent bg stripes from moving when position is fixed:
  transform: translate3d(0, 0, 0);

  &__row {
    display: flex;
    flex-direction: column;

    @media screen and (orientation: landscape) {
      flex-direction: row;
    }
  }

  &__col {
    order: 1;
    flex-basis: 50%;

    @media screen and (orientation: portrait) {
      background-color: #121212;
      order: 0;
    }

    @media screen and (orientation: landscape) {
      min-height: calc(100vh - 48px);

      @include media-breakpoint-down(xs) {
        min-height: calc(100vh - 39px);
      }

      @include media-breakpoint-up(sm) {
        flex-basis: 57%;
        min-height: calc(100vh - 49px);
      }
    }
  }

  &__col:nth-child(2) {
    order: 0;
    min-height: calc(45vh - 45px);
    padding: 0 20px 20px;

    @media screen and (orientation: portrait) {
      order: 1;
    }

    // tablet devices
    @media screen and (min-width: 912px) and (orientation: portrait) {
      padding: 30px 80px 0;
    }

    @media screen and (max-width: 667px) and (orientation: landscape) {
      min-height: calc(100vh - 39px);
    }

    @media screen and (orientation: landscape) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      z-index: 2;

      @include media-breakpoint-up(lg) {
        flex-basis: 43%;
        padding: 0;
        padding-left: 30px;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 50px;
      }
    }
  }

  &__content {
    position: relative;
    height: 100%;

    // overlay mask
    &:before {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );

      @media screen and (orientation: landscape) {
        width: 200%;
        left: -100%;
        z-index: 1;
      }
    }

    #{$this}__vid {
      background-clip: content-box;
      width: 100%;
      object-fit: cover;
      min-height: 100%;

      @media screen and (orientation: portrait) {
        min-height: 55vh;
      }

      @media screen and (orientation: landscape) {
        position: absolute;
        top: 0;
        left: -35%;
        width: 135%;
      }
    }

    #{$this}__texts {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      p {
        // buzz words
        padding: 0;
        margin: 0;
        font-weight: 600;
        color: #fff;
        font-size: 70px;

        @media screen and (max-width: 375px) and (orientation: portrait) {
          font-size: 50px;
        }

        // tablet devices
        @media screen and (min-width: 768px) and (orientation: portrait) {
          font-size: 110px;
        }

        @media screen and (max-width: 667px) and (orientation: landscape) {
          font-size: 45px;
        }

        @media screen and (max-width: 586px) and (max-height: 300px) and (orientation: landscape) {
          font-size: 45px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 110px;
        }
      }
    }

    #{$this}__overlay-portrait-bg {
      &-1,
      &-2,
      &-3 {
        display: none;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0;
        clip-path: polygon(0 80px, 100% 0, 100% 100%, 0% 100%);

        @media screen and (orientation: portrait) {
          display: block;
        }

        @include media-breakpoint-up(sm) {
          clip-path: polygon(0 150px, 100% 0, 100% 100%, 0% 100%);
        }
      }

      &-1 {
        background-color: #121212;
        height: 100px;

        @include media-breakpoint-up(sm) {
          height: 150px;
        }
      }

      &-2 {
        background-color: rgba(18, 18, 18, 0.3);
        height: 135px;

        @include media-breakpoint-up(sm) {
          height: 200px;
        }
      }

      &-3 {
        background-color: rgba(18, 18, 18, 0.3);
        height: 155px;

        @include media-breakpoint-up(sm) {
          height: 235px;
        }
      }
    }
  }

  &__logo {
    height: 41.39px;
    margin-bottom: 15px;

    @media screen and (max-width: 375px) and (orientation: portrait) {
      height: 38px;
      margin-bottom: 12px;
    }

    // tablet devices
    @media screen and (min-width: 768px) and (orientation: portrait) {
      height: 79.97px;
    }

    @media screen and (max-width: 667px) and (orientation: landscape) {
      height: 33px;
    }

    @include media-breakpoint-up(lg) {
      height: 79.97px;
      margin-bottom: 50px;
    }
  }

  &__message {
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 16px;

    @media screen and (max-width: 375px) and (orientation: portrait) {
      font-size: 15px;
    }

    @media screen and (min-width: 568px) and (orientation: landscape) {
      line-height: 19.2px;
      font-size: 12px;
      padding-right: 12%;
      line-height: 1.2;
    }

    @media screen and (min-width: 774px) and (orientation: landscape) {
      font-size: 15px;
      padding-right: 11%;
      line-height: 1.2;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }

    // tablet devices
    @media screen and (min-width: 768px) and (orientation: portrait) {
      font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
      line-height: 28.8px;
      font-size: 24px;
      padding-right: 10%;
    }
  }

  &__message:nth-of-type(2) {
    margin-top: 30px;

    @media screen and (orientation: landscape) {
      margin-top: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 50px;
    }
  }

  &__overlay-landscape-bg {
    &-1,
    &-2,
    &-3 {
      position: fixed;
      height: 100%;
      width: 100%;
      bottom: 0;
      clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
      display: none;

      @media screen and (orientation: landscape) {
        display: block;
      }
    }

    &-1 {
      background-color: #121212;
      max-width: calc(47.4% + 5%);

      @include media-breakpoint-up(lg) {
        max-width: 47.4%;
      }
    }

    &-2 {
      background-color: rgba(18, 18, 18, 0.3);
      max-width: calc(50.5% + 5%);

      @include media-breakpoint-up(lg) {
        max-width: 50.5%;
      }
    }

    &-3 {
      background-color: rgba(18, 18, 18, 0.3);
      max-width: calc(52.5% + 5%);

      @include media-breakpoint-up(lg) {
        max-width: 52.5%;
      }
    }
  }

  &__footer {
    position: relative;
    z-index: 2;
    border-top: 1px solid #333;
  }
}

.home-video {
  $mobile-height: calc(100vh - 48px);
  $desktop-height: calc(100vh - 48px);
  $smallest-height: calc(100vh - 38px);
  overflow: hidden;
  height: $mobile-height; // vertical height minus footer size
  position: relative;
  border-bottom: 1px solid #333;

  @media screen and (max-width: 320px) and (orientation: portrait) {
    overflow-y: auto;
    height: $smallest-height;
  }

  @include media-breakpoint-down(xs) {
    height: $smallest-height;
  }

  @include media-breakpoint-up(lg) {
    height: $desktop-height;
  }

  &__body-overlay {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      height: 60%;
      left: -35%;
      width: 200%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );

      @media screen and (max-width: 320px) and (orientation: portrait) {
        width: 100%;
        left: 0;
        min-height: 400px;
      }

      @media screen and (min-width: 321px) and (orientation: landscape) {
        left: 0;
        width: 100%;
        height: 100%;
      }

      //   @include media-breakpoint-up(lg) {
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //   }
    }
  }

  &__bg-vid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56%;
    z-index: -100;
    background-clip: content-box;
    object-fit: cover;

    @media screen and (max-width: 320px) and (orientation: portrait) {
      min-height: 400px;
    }

    @media screen and (min-width: 321px) and (orientation: landscape) {
      height: 100%;
      left: 12%;
    }

    // @include media-breakpoint-up(lg) {
    //   height: 100%;
    //   left: 12%;
    // }
  }

  &__container {
    position: relative;
    width: 100%;
    height: $mobile-height;

    @media screen and (min-width: 321px) and (orientation: landscape) {
      height: $desktop-height;
    }

    // @include media-breakpoint-up(lg) {
    //   height: $desktop-height;
    // }

    &-overlay-bg1,
    &-overlay-bg2,
    &-overlay-bg3 {
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);

      @media screen and (max-width: 320px) and (orientation: portrait) {
        bottom: -20%;
      }

      @media screen and (min-width: 321px) and (orientation: landscape) {
        left: 0;
        top: 0;
        clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
      }

      //   @include media-breakpoint-up(lg) {
      //     left: 0;
      //     top: 0;
      //     clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
      //   }
    }

    &-overlay-bg1 {
      background-color: #121212;
      //   max-height: 54%;
      max-height: 50%;

      @media screen and (min-width: 321px) and (orientation: landscape) {
        max-height: 100%;
        max-width: calc(47.4% + 5%);
      }

      @include media-breakpoint-up(lg) {
        max-height: 100%;
        max-width: 47.4%;
      }
    }

    &-overlay-bg2 {
      background-color: rgba(18, 18, 18, 0.3);
      //   max-height: 57.1%;
      max-height: 53.1%;

      @media screen and (min-width: 321px) and (orientation: landscape) {
        max-height: 100%;
        max-width: calc(50.5% + 5%);
      }

      @include media-breakpoint-up(lg) {
        max-height: 100%;
        max-width: 50.5%;
      }
    }

    &-overlay-bg3 {
      background-color: rgba(18, 18, 18, 0.3);
      //   max-height: 59.1%;
      max-height: 55.2%;

      @media screen and (min-width: 321px) and (orientation: landscape) {
        max-height: 100%;
        max-width: calc(52.5% + 5%);
      }

      @include media-breakpoint-up(lg) {
        max-height: 100%;
        max-width: 52.5%;
      }
    }
  }

  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 321px) and (orientation: landscape) {
      flex-direction: row;
    }

    // @include media-breakpoint-up(lg) {
    //   flex-direction: row;
    // }

    div:nth-of-type(1) {
      order: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      z-index: 100;
      padding: 0 20px;
      flex-basis: 50%;

      @media screen and (max-width: 320px) and (orientation: portrait) {
        background-color: #121212;
      }

      @media screen and (min-width: 321px) and (orientation: landscape) {
        order: 0;
        padding: 0;
        padding-left: 20px;
        flex-basis: 50%;
      }

      @include media-breakpoint-up(lg) {
        order: 0;
        padding: 0;
        padding-left: 50px;
        flex-basis: 43%;
      }

      .logo {
        margin-bottom: 15px;
        height: 41.39px;

        @media screen and (max-width: 320px) and (orientation: portrait) {
          height: 38.08px;
        }

        @media screen and (max-width: 568px) and (orientation: landscape) {
          height: 33px;
        }

        @include media-breakpoint-up(lg) {
          height: 79.97px;
          margin-bottom: 50px;
        }
      }

      p {
        line-height: 19.2px;
        font-size: 16px;
        padding: 0;
        margin: 0;
        color: #fff;

        @media screen and (max-width: 320px) and (orientation: portrait) {
          font-size: 15px;
          padding-right: 0 !important;
        }

        @media screen and (max-width: 568px) and (orientation: landscape) {
          font-size: 12px;
          line-height: 1.2;
          padding-right: 13%;
        }

        @media screen and (min-width: 569px) and (orientation: landscape) {
          font-size: 15px;
          padding-right: 11%;
        }

        @include media-breakpoint-up(lg) {
          line-height: 28.8px;
          font-size: 24px;
          padding-right: 10%;
        }

        &:nth-of-type(2) {
          margin-top: 40px;

          @media screen and (max-width: 568px) and (orientation: portrait) {
            padding-bottom: 20px;
          }

          @include media-breakpoint-down(sm) {
            margin-top: 20px;
          }

          @include media-breakpoint-up(sm) {
            padding-bottom: 0;
            margin-top: 20px;
          }

          @include media-breakpoint-up(lg) {
            padding-bottom: 0;
            margin-top: 50px;
          }
        }
      }
    }

    div:nth-of-type(2) {
      order: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: 50%;

      @media screen and (max-width: 320px) and (orientation: portrait) {
        min-height: 400px;
      }

      @media screen and (min-width: 321px) and (orientation: landscape) {
        height: 100%;
        order: 1;
        // padding-left: 10%;
        justify-content: center;
        align-items: center;
        flex-basis: 57%;
      }

      //   @include media-breakpoint-up(lg) {
      //     height: 100%;
      //     order: 1;
      //     // padding-left: 10%;
      //     justify-content: center;
      //     align-items: center;
      //     flex-basis: 57%;
      //   }

      p {
        margin: 0;
        font-size: 70px;
        color: #fff;
        font-weight: 600;

        @media screen and (max-width: 320px) and (orientation: portrait) {
          font-size: 50px;
        }

        @media screen and (max-width: 667px) and (orientation: landscape) {
          font-size: 45px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 110px;
        }
      }
    }
  }
}

// @keyframes fillLeftToRight {
//   from {
//     background-position: 0 0;
//   }
//   to {
//     background-position: 100% 0;
//   }
// }

@keyframes fillLeftToRight {
  0% {
    width: 0;
  }
  100% {
    width: calc(100vw - 70px);
  }
}

@keyframes logoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentSlideIn {
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes animatedContentSlideIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
