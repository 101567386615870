.header {
  $self: &;
  position: fixed;
  width: 100%;
  height: 54px;
  top: 0;
  left: 0;
  z-index: 1030;
  background-color: $white;

  &__container {
    display: flex;
    @extend .container;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }

  &__logo {
    img {
      height: 100%;
      width: 140px;
    }
  }

  &__buttons {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__mobile-nav {
    position: relative;
    display: block;
    margin-top: 4px;

    @include media-breakpoint-up(md) {
      display: none;
    }

    .icon.icon-burger-menu {
      position: absolute;
      top: -12px;
      right: 5px;
      z-index: 999999;

      &:after {
        display: none;
      }
    }

    .btn {
      min-height: 32px;
    }

    &-menu.show {
      text-align: center;
      width: 134px;
      border: none;
      box-shadow: 0px 3px 6px #00000029;
      transform: translate(-150px, 23px) !important;
      padding: $space-2 $space-4 $space-3;
    }
  }

  .logout-btn {
    color: $gray-900;
    transition: all 0.15s ease-in-out;
    &:hover {
      color: $blue-200;
    }
  }
}
