.toast {
  border: $border;
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
  border-left: 5px solid $success;
  background-color: $white;

  &:not(:last-child) {
    margin-bottom: $space-1;
  }

  &__wrapper {
    position: fixed;
    top: 60px;
    right: 10px;
    z-index: 1030;
    width: 320px;
    z-index: 99999;
    left: 50%;
    transform: translate(-50%, 0px);
  }

  &-header {
    display: flex;
    align-items: center;
    padding: $space-1 $space-2;
    color: $dark;
    border-bottom: $border;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &-body {
    padding: $space-1 $space-2;
  }

  // modifier

  &--info {
    border-left-color: $secondary;
  }

  &--warning {
    border-left-color: $yellow;
  }

  &--danger {
    border-left-color: $danger;
  }
}
