.card-business-card {
  $this: &;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  @include media-breakpoint-up(sm) {
    max-width: 375px;
    margin: 0 auto;
  }

  &__company-logo {
    align-self: center;
  }

  &__employee-image {
    overflow: hidden;
    background-color: #fff;
    max-height: 375px;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__employee-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #dedede;
    padding: $space-3;

    .employee-name {
      font-weight: 600;
      font-size: 18px;
    }
  }

  &__employee-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin: 0;
    list-style: none;
    background-color: #dedede;

    li {
      display: inline-block;
      padding-right: 10px;
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .employee-division {
    display: flex;
    align-items: center;
  }

  .icon-holder {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__employee-additional-details {
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    background-color: #dedede;
    padding: $space-3 $space-4;

    li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      p {
        margin: 0;
      }
    }
    
    .employee-email a {
      color: $red-400;
      text-decoration: underline;
    }
  }

  &__footer {
    padding: $space-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eff1f3;

    #{$this}__company-socials {
      padding-left: 0;
      list-style: none;

      li {
        display: inline-block;
        padding-right: 10px;
        padding-left: 0;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .copyright {
      font-size: 12px;
    }

    .terms {
      padding: 0;
      margin-top: 10px;
      margin-bottom: 0;
      display: flex;
      padding-left: 0;
      list-style: none;
      padding-bottom: 0;

      li:first-child {
        a {
          padding-right: 10px;
        }
      }

      li:not(:first-child) {
        &:before {
          content: "";
          border-right: 1px solid #ccc;
        }

        a {
          padding-left: 10px;
        }
      }

      a {
        color: inherit;
        font-size: 12px;
      }
    }
  }
}
