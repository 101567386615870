.icon {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;

  &-social {
    border-radius: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;

    a {
      color: $white;
    }

    &.facebook {
      background: #3b5998;
    }

    &.twitter-x {
      background: #000000;
    }

    &.instagram {
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    }

    &.youtube {
      background: #ff0000;
    }

    &.linkedin {
      background: #0e76a8;
    }

    .icon {
      display: flex;
    }
  }

  &-bell-default {
    font-size: 40px;
  }

  &-option {
    border-radius: 100%;
    background: #eff1f3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;

    a {
      color: #000;
    }

    .icon {
      display: flex;
    }
  }
}

.icon-sprite {
  display: inline-block;
}

.icon-sprite-plus,
.icon-sprite-minus {
  cursor: pointer;
}
