body {
    color: $black;
    font-family: "myriad-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    top: 0 !important; // to override google-translate inline styles

    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }

    p {
        color: $black;
    }
}

a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none;
}

a {
    color: $blue-200;

    &:hover {
        color: darken($color: $blue-200, $amount: 10);
    }

    &.view-more,
    &.view-more:hover {
        color: $blue-200;
        cursor: pointer;
    }
}

strong {
    font-weight: 500;
}

// For Headings
@each $heading, $value in $custom-headings {
    #{$heading} {
        font-size: map-deep-get($custom-headings, $heading, "xs");

        @each $breakpoint, $size in map-deep-get($custom-headings, $heading) {
            $i: index(
                map-deep-get($custom-headings, $heading),
                $breakpoint $size
            );

            @if $i > 1 {
                @include media-breakpoint-up($breakpoint) {
                    font-size: $size;
                }
            }
        }
    }
}

// font size
@each $font, $value in $font-sizes {
    .fs-#{$font} {
        font-size: $value !important;
    }
}

// font colors
@each $fontColor, $value in $font-colors {
    .text-#{$fontColor} {
        color: $value !important;
    }
}
