// Responsive Border Utilities
@each $breakpoint in map-keys($grid-breakpoints) {
    @each $border-pos in null, top, left, bottom, right {
        .border-#{$breakpoint}#{util-infix($border-pos)} {
            @include media-breakpoint-up($breakpoint) {
                border#{util-infix($border-pos)}: $border-width solid
                    $border-color !important;
            }
        }

        .border-#{$breakpoint}#{util-infix($border-pos)}-0 {
            @include media-breakpoint-up($breakpoint) {
                border#{util-infix($border-pos)}-width: 0 !important;
            }
        }
    }
}

// responsive width utilities
@each $breakpoint in map-keys($grid-breakpoints) {
    @each $prop, $value in $custom-widths {
        .w-#{$breakpoint}#{util-infix($prop)} {
            @include media-breakpoint-up($breakpoint) {
                width: $value !important;
            }
        }
    }
}

// line height utilities
@each $breakpoint in map-keys($grid-breakpoints) {
    @each $prop, $value in $line-heights {
        .lh-#{$breakpoint}#{util-infix($prop)} {
            @include media-breakpoint-up($breakpoint) {
                line-height: $value !important;
            }
        }
    }
}

.bg-gray {
    background-color: $gray-300;

    &-200 {
        background-color: $gray-200;
    }

    &-400 {
        background-color: $gray-400;
    }

    &-500 {
        background-color: $gray-500;
    }

    &-900 {
        background-color: $gray-900;
    }
}

@each $name, $color in $backgroundColors {
    .bg-#{$name} {
        background-color: $color !important;
        color: color-yiq($color);
    }
}

.box-shadow {
    box-shadow: 0 3px 6px #231f200a;
}

.line-separator {
    display: block !important;
    width: 100%;
    border-bottom: $border;

    &--last:last-child {
        border-bottom: none;
    }
}

// used in About Us page
.overflow-x {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.disableClick {
    pointer-events: none;
}

.font-weight-semibold {
    font-weight: 500;
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.section-title {
    padding: 5px 10px;
    background-color: #f5f7f8;
    font-size: 14px;

    &--bg-green {
        background-color: #6fb827;
        color: $white;
    }

    &--admin-news {
        height: auto;
    }

    &--bg-gray-300 {
        background-color: $gray-300;
    }
}

.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
}
