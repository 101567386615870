@mixin custom-grid($breakpoint, $divider, $margin) {
  @if $breakpoint == "xs" {
    width: calc(100% / #{$divider} - (2 * #{$margin})) !important;
    margin: #{$margin};
  } @else {
    @include media-breakpoint-up($breakpoint) {
      width: calc(100% / #{$divider} - (2 * #{$margin})) !important;
      margin: #{$margin};
    }
  }
}

@mixin gutterReset() {
  margin-left: -5px;
  margin-right: -5px;
  & > .col,
  & > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@mixin gutters($breakpoint) {
  @if $breakpoint == "sm" {
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-up($breakpoint) {
      @include gutterReset();
    }
  } @else if $breakpoint == "md" {
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-up($breakpoint) {
      @include gutterReset();
    }
  } @else if $breakpoint == "lg" {
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-up($breakpoint) {
      @include gutterReset();
    }
  } @else {
    @include gutterReset();
  }
}
