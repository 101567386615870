// Black
$black: #333333;
$pitch-black: #000;

// Gray
$gray-100: #fafafa;
$gray-200: #f5f7f8;
$gray-300: #f1f1f1;
$gray-400: #eff1f2;
$gray-500: #eaeaea;
$gray-600: #d7d7d7;
$gray-700: #c6c6c6;
$gray-800: #999999;
$gray-900: #666666;

// Red
$red-100: #ffe5e5;
$red-200: #f7665f;
$red-300: #f25951;
$red-400: #fe001a;
$red-500: #dd271d;

// White
$white: #ffffff;

// Blue
$blue-100: #33c5ff;
$blue-200: #00aaee;
$blue-300: #006fbe;

// Green
$green-100: #6fb827;
$green-200: #04e08e;
$green-300: #00d129;
$green-400: #00ff93;
$green-500: #def4e3;

$yellow-100: #ffd839;

// Body
$body-bg-color: #e9e9e9;
$body-color: $black;
$body-font-size: 16px;
$body-font-size--mobile: 16px;

// Border
$border: 1px solid $gray-600;
$border-black: 1px solid $black;

// font
$font-xs: 12px;
$font-sm: 14px;
$font-regular: 16px;
$font-md: 18px;
$font-lg: 22px;
$font-xl: 26px;

// Custom Spacing
$space-1: 5px;
$space-2: 10px;
$space-3: 15px;
$space-4: 20px;
$space-5: 25px;
$space-6: 30px;

// Ticket Status Colors
$ticket-resolved: #85b236;
$ticket-closed: $gray-800;
$ticket-open: $gray-900;
$ticket-blocked: #ed1c24;
$ticket-deferred: #c48c4b;
$ticket-in-progress: #405d93;
$ticket-awaiting-customer: #a840ad;
$ticket-on-hold: #ffc400;
$ticket-reopened: #ffa429;

// mobile and desktop headings
$custom-headings: (
  "h1": (
    "xs": 32px,
    "sm": 32px,
    "md": 60px,
    "lg": 60px,
  ),
  "h2": (
    "xs": 28px,
    "sm": 28px,
    "md": 42px,
    "lg": 42px,
  ),
  "h3": (
    "xs": 26px,
    "sm": 26px,
    "md": 36px,
    "lg": 36px,
  ),
  "h4": (
    "xs": 24px,
    "sm": 24px,
    "md": 30px,
    "lg": 30px,
  ),
  "h5": (
    "xs": 20px,
    "sm": 20px,
    "md": 24px,
    "lg": 24px,
  ),
  "h6": (
    "xs": 16px,
    "sm": 16px,
    "md": 18px,
    "lg": 18px,
  ),
);

$custom-widths: (
  "25": 25%,
  "50": 50%,
  "75": 75%,
  "100": 100%,
  "auto": auto,
);

$font-sizes: (
  "1": 10px,
  "2": 12px,
  "3": 14px,
  "4": 16px,
  "5": 18px,
  "6": 20px,
  "7": 28px,
);

$line-heights: (
  "0": 0,
  "12": 12px,
  "14": 14px,
  "16": 16px,
  "18": 18px,
  "20": 20px,
  "22": 22px,
  "24": 24px,
  "26": 26px,
  "28": 28px,
  "30": 30px,
  "32": 32px,
);

$font-colors: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
);

// Buttons
$btnDesktopHeight: 32px;
$btnHeightMobile: 40px;
$btnRadius: 20px;

$backgroundColors: (
  lilac: #ba6dc1,
  amethyst: #946ace,
);

// colors
$primary: #00aaee;
$border-color: #d7d7d7;
$success: #27b843;
$secondary: #666666;
$danger: #fe001a;
$dark: #333333;
$yellow: #ffd839;
$input-color: #333;
